@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HKGrotesk-Bold";
  font-display: swap;
  src: url("./fonts/HKGrotesk/HKGrotesk-Bold.otf");
}
@font-face {
  font-family: "HKGrotesk-Light";
  font-display: swap;
  src: url("./fonts/HKGrotesk/HKGrotesk-Light.otf");
}

@font-face {
  font-family: "HKGrotesk-Medium";
  font-display: swap;
  src: url("./fonts/HKGrotesk/HKGrotesk-Medium.otf");
}

@font-face {
  font-family: "HKGrotesk-Regular";
  font-display: swap;
  src: url("./fonts/HKGrotesk/HKGrotesk-Regular.otf");
}

@font-face {
  font-family: "DelaGothic-Regular";
  font-display: swap;
  src: url("./fonts/Dela_Gothic/DelaGothicOne-Regular.ttf");
}

@font-face {
  font-family: "PurplePurse-Regular";
  font-display: swap;
  src: url("./fonts/Purple_Purse/PurplePurse-Regular.ttf");
}

@font-face {
  font-family: "PurplePurse-Regular";
  font-display: swap;
  src: url("./fonts/Purple_Purse/PurplePurse-Regular.ttf");
}

@font-face {
  font-family: "Inter-Black";
  font-display: swap;
  src: url("./fonts/Inter/Inter-Black.otf");
}
@font-face {
  font-family: "Inter-BlackItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-BlackItalic.otf");
}
@font-face {
  font-family: "Inter-Bold";
  font-display: swap;
  src: url("./fonts/Inter/Inter-Bold.otf");
}
@font-face {
  font-family: "Inter-BoldItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-BoldItalic.otf");
}
@font-face {
  font-family: "Inter-ExtraBold";
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraBold.otf");
}
@font-face {
  font-family: "Inter-ExtraBoldItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraBoldItalic.otf");
}
@font-face {
  font-family: "Inter-ExtraLight";
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraLight.otf");
}
@font-face {
  font-family: "Inter-ExtraLightItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-ExtraLightItalic.otf");
}
@font-face {
  font-family: "Inter-Medium";
  font-display: swap;
  src: url("./fonts/Inter/Inter-Medium.otf");
}
@font-face {
  font-family: "Inter-MediumItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-MediumItalic.otf");
}
@font-face {
  font-family: "Inter-Regular";
  font-display: swap;
  src: url("./fonts/Inter/Inter-Regular.otf");
}
@font-face {
  font-family: "Inter-SemiBold";
  font-display: swap;
  src: url("./fonts/Inter/Inter-SemiBold.otf");
}
@font-face {
  font-family: "Inter-SemiBoldItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-SemiBoldItalic.otf");
}
@font-face {
  font-family: "Inter-Thin";
  font-display: swap;
  src: url("./fonts/Inter/Inter-Thin.otf");
}
@font-face {
  font-family: "Inter-ThinItalic";
  font-display: swap;
  src: url("./fonts/Inter/Inter-ThinItalic.otf");
}
@font-face {
  font-family: "Inter-V";
  font-display: swap;
  src: url("./fonts/Inter/Inter-V.ttf");
}

@font-face {
  font-family: "Poppins-Black";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Black.ttf");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-BlackItalic.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-BoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Poppins-Italic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Italic.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-LightItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-LightItalic.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-MediumItalic.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBoldItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Poppins-Thin";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "Poppins-ThinItalic";
  font-display: swap;
  src: url("./fonts/Poppins/Poppins-ThinItalic.ttf");
}
@font-face {
  font-family: "Nova-Regular";
  font-display: swap;
  src: url("./fonts/Nova/ProximaNova-Regular.otf");
}
@font-face {
  font-family: "Space-Bold";
  font-display: swap;
  src: url("./fonts//Space_Grotesk/static/SpaceGrotesk-Bold.ttf");
}

@layer components {
  .activeButton {
    @apply shadow-lg flex justify-center items-center buttonFieldShawdow transition-all px-16 py-14 text-center bg-newLTPrimary text-textColor whitespace-nowrap rounded-xl hover:bg-opacity-80 font-hkMedium text-buttonText;
  }
  .InActiveButton {
    @apply shadow-lg flex justify-center items-center buttonFieldShawdow px-16 py-14 bg-bgInactiveButton text-white rounded-xl whitespace-nowrap font-hkMedium text-buttonText;
  }
  .activeIconButton {
    @apply shadow-lg buttonFieldShawdow  text-center px-16 py-14 bg-bgActiveButton text-textColor rounded-xl  hover:bg-opacity-80   whitespace-nowrap font-hkMedium my-0 mx-auto text-buttonText;
  }
  .InActiveIconButton {
    @apply shadow-lg buttonFieldShawdow px-16 py-14 bg-bgInactiveButton text-white rounded-xl  relative whitespace-nowrap font-hkMedium my-0 mx-auto   text-buttonText;
  }
  .InActiveOpt {
    @apply rounded-md w-14 h-14 lg:w-20 lg:h-20 p-ten border-custom border-borderColor  text-center xs:text-2xl  text-opt  font-hkBold focus:ring-0 focus:outline-none   focus:border-bgActiveButton text-[#383E4F] placeholder-opacity-10;
  }
  .ActiveOpt {
    @apply rounded-md w-14 h-14 lg:w-16 lg:h-16 p-ten border-custom border-borderColor  text-center  xs:text-2xl  text-opt   font-hkBold  focus:ring-0 focus:outline-none   focus:border-newLTPrimary text-[#383E4F] placeholder-[#eee];
  }
  .label {
    @apply font-hkBold text-sm;
  }
  .input {
    @apply rounded-md border-custom border-borderColor placeholder-borderColor text-inputColor p-3 font-hkRegular text-xl outline-none   outline-0  focus:ring-0 focus:border-bgActiveButton;
  }
  .inputMobile {
    @apply rounded-t-md border-0  placeholder-borderColor text-inputColor p-3 font-hkRegular text-xl outline-none outline-0  focus:ring-0 focus:border-bgActiveButton;
  }
  .passwordMobile {
    @apply rounded-b-md border-t-2 border-l-0 border-b-0 border-r-0 border-borderColor  placeholder-borderColor text-inputColor p-3 font-hkRegular text-xl outline-none   outline-0  focus:ring-0 focus:border-borderColor;
  }
  .errorInput {
    @apply rounded-md border-custom border-red placeholder-borderColor p-3 font-hkRegular text-xl outline-none  text-inputColor   outline-0  focus:ring-0;
  }
  .errorDropdown {
    @apply rounded-md border-custom border-red placeholder-borderColor font-hkRegular text-xl outline-none text-inputColor outline-0 focus:ring-0;
  }
  .errorMessage {
    @apply text-red font-hkBold text-base;
  }
  .activeTab {
    @apply bg-stepperChecked xs:px-3 py-3 xs:text-badge text-sm font-PoppinsSemiBold text-black inputFieldShawdow outline-none  cursor-pointer;
  }
  .inActiveTab {
    @apply xs:px-3 py-3 xs:text-badge text-sm font-hkBold text-black cursor-pointer;
  }
  .activeMobileTab {
    @apply relative bg-white xs:px-3 py-3 xs:text-badge text-xs font-PoppinsSemiBold text-bgActiveButton outline-none inputFieldShawdow  cursor-pointer;
  }
  .inActiveMobileTab {
    @apply relative bg-white xs:px-3 py-3 xs:text-badge text-xs text-gray4 font-PoppinsLight inputFieldShawdow cursor-pointer;
  }

  .baseText {
    @apply font-hkRegular text-sm lg:text-base text-center text-[#6E728D];
  }
  .baseText1 {
    @apply ml-2  text-[#6E728D] font-hkBold text-base lg:text-lg cursor-pointer underline;
  }

  .cardInfo {
    @apply font-bold font-hkBold text-sm text-[#383E4F];
  }
}
input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .dot1 {
  background-color: #5468ff !important;
}

.inputFieldShawdow {
  box-shadow: 0px 12px 19px rgba(60, 128, 209, 0.0851449);
}

.buttonFieldShawdow {
  box-shadow: 0px 10px 25px rgba(84, 104, 255, 0.3);
}

.coverShawdow {
  box-shadow: 2.80109px 2.80109px 9.33696px rgba(209, 209, 209, 0.5);
}

.circleCheck > input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin-right: 8px;
  cursor: progress;
}

.react-select__input-container{
  position: relative;
}
.react-select__input-container > input[type="text"] {
  position: absolute;
}
blockquote {
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
}
/* Thanks: http://callmenick.com/post/styling-blockquotes-with-css-pseudo-classes */
blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  float: right;
  font-size: 6em;
  line-height: 1;
  top: -10px;
  left: 0;
  content: "\201C";
}
blockquote:after {
  font-family: Georgia, serif;
  position: absolute;

  /* display: block; don't use this, it raised the quote too high from the bottom - defeated line-height? */

  font-size: 6em;
  line-height: 1;
  right: 0;
  bottom: -0.5em;
  content: "\201D";
}

.my-popup-content {
  margin: auto;
  background: rgb(19, 190, 98);

  padding: 5px;
}

/* @media screen and (max-width: 680px) {
  .popup-content {
    width: 40%;
  }
}

@media screen and (min-width: 786px) {
  /* .popup-content {
    width: 30%;
  }
} */

.tab-item {
  width: calc(100% / 3);
}

.tab-items {
  width: calc(100% / 4);
}

.eld-item {
  width: calc(100% / 2.5);
}

/* select component */
.react-select__control:hover {
  box-shadow: 0px 12px 19px rgba(60, 128, 209, 0.0851449);
  cursor: pointer;
}

.react-select__option:hover {
  background-color: #5468ff;
  color: #ffffff;
}

.boxFooter {
  height: 100px;
  border: solid 5px #000;
  border-color: transparent transparent #000 transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input.empty::-webkit-datetime-edit {
  color: #e5e5e5;
}

.percentage::after {
  content: "%";
}

.hero:after {
  content: "";
  position: absolute;
  top: 97%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 19px #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: solid 16px transparent;
  border-right: solid 16px transparent;
}
.arrow-top:after {
  content: " ";
  width: 30px;
  height: 30px;
  position: absolute;
  border-top: 1.2px solid #d7d8dc;
  border-right: 0px solid #d7d8dc;
  border-left: 1.2px solid #d7d8dc;
  border-bottom: 0px solid #d7d8dc;
  transform: rotate(45deg);
  bottom: 100%;
  background-color: #fff;
  left: 80%;
  margin-left: -25px;
  margin-bottom: -14px;
}

.selected:after {
  top: 78%;
  left: 75%;
  width: 17px;
  height: 17px;
  margin-left: -25px;
  margin-bottom: -19px;
  transform: rotate(45deg);

  content: "";
  position: absolute;
  background-color: #fff;
  border-top: 0px solid transparent;
  border-left: 0px solid transparent;
}

.selectedCard:after {
  bottom: 95%;
  left: 18%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #fff;
  border-width: 20px;
}

.selectedCard2:after {
  bottom: 95%;
  left: 68%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #fff;
  border-width: 20px;
}

body {
  background-color: #fff;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

body::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f8fbff;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: #9fa9bd;
  border-radius: 5px;
}

div::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 5px;
}
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f8fbff;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #9fa9bd;
  border-radius: 5px;
}
section .content {
  position: relative;
}

.box1 {
  position: relative;
  width: 60%;
  height: auto;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
}

.box1:after {
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  top: 50%;
  left: 0;
  margin-left: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: #fff;
}
.box2 {
  position: relative;
  width: 60%;
  height: auto;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;
}
.boxCall {
  position: relative;
  width: 100%;
  height: auto;
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-sizing: border-box;
}

.boxCall:after {
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  top: 50%;
  right: 0;
  margin-right: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: #fff;
}

.box2:after {
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  top: 50%;
  right: 0;
  margin-right: -25px;
  content: "";
  transform: rotate(45deg);
  margin-top: -25px;
  background: #fff;
}
.landingHome {
  background-image: url("/Images/bgTry.webp");
  background-repeat: no-repeat;
  background-position: 0% 0%;
  min-width: 600px;
  height: 400px;
  background-size: 100% 100%;
}
.landing {
  background-image: url("/Images/bgTry.webp");
  background-repeat: no-repeat;
  background-position: 0% 0%;
  min-width: 600px;
  height: 750px;
  background-size: 100% 100%;
}

.landingMob {
  background-image: url("/Images/bgTry.webp");
  background-repeat: no-repeat;
  background-position: 0% 0%;
  min-width: 380px;
  height: 1370px;
  object-position: bottom;
  background-size: 100% 100%;
}

/* blockquote{
  display: inline;
}


blockquote::before{
  content: open-quote;
  color: #000;
}

blockquote::after{
  content: close-quote;
  padding-left: 10px;
  color: #000;
} */

ol {
  list-style: auto;
  margin-left: 1rem;
}
.blogList > ul {
  list-style: disc;
  margin-left: 1rem;
}
.blogCover {
  height: 60vh;
}
@media screen and (max-width: 766px) {
  .landing .blogCover {
    height: 40vh;
  }
}

.spacer {
  width: 100%;
  height: 95px;
}

@media screen and (max-width: 481px) {
  .landing,
  .landingHome {
    background-image: url("/Images/bgTry.webp");
    background-repeat: no-repeat;
    background-position: 0% 0%;
    min-width: 380px;
    height: 900px;
    background-size: 100% 100%;
  }
  .landingCont {
    margin-top: 18rem;
  }
  .react-select__option{
    padding: 8px 8px;
    font-size: 14px;
  }
}
section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #eeeefc;
  height: 100%;
  border-radius: 0 0 50% 50%/0 0 10% 10%;
}

.active {
  color: #5468ff;
  text-decoration: underline;
  text-underline-position: under;
}

.testGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px;
  margin: 0 auto;
  align-items: center;
}

.testGridMtc {
  display: grid;
  grid-template-columns: 0.7fr 0.6fr;
  gap: 96px;
  margin: 0 auto;
  align-items: center;
}
.testGridBenefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px;
  margin: 0 auto;
  align-items: center;
}

.testGridMtc2 {
  display: grid;
  grid-template-columns: 0.5fr 0.8fr;
  margin: 0 auto;
  align-items: center;
}

.testGridMtc3 {
  display: grid;
  grid-template-columns: 0.8fr 0.5fr;
  margin: 0 auto;
  align-items: center;
}
.testGridMtc1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  margin: 0 auto;
  align-items: center;
}

.mtcPadding {
  padding: 20px 0px 20px 130px;
}
.mtcPadding1 {
  padding: 20px 130px 20px 0px;
}
.mainPadding {
  padding: 20px 200px;
}

.mainCallPadding {
  margin: 50px 300px;
}

.mainPaddingDispatcher {
  padding: 20px 96px;
}
.mainPaddingDispatcher1 {
  padding: 5px 340px;
}
*:focus {
  outline: none;
}
@media screen and (max-width: 599px) {
  .testGrid {
    grid-template-columns: 1fr;
    gap: 32px;
  }
  .chip {
    height: 60px;
    background-color: #5468ff;
  }
  .testGridMtc2 {
    grid-template-columns: 1fr;
  }

  .testGridMtc3 {
    grid-template-columns: 1fr;
  }
  .mtc {
    order: 2;
  }
  .mtc1 {
    order: 1;
  }
  .benefit {
    width: 100%;
    padding-top: 35px;
  }
  .testGridMtc {
    gap: 32px;
    grid-template-columns: 1fr;
  }
  .testGridBenefits {
    gap: 32px;
    grid-template-columns: 1fr;
  }
  .mainPadding {
    padding: 20px 22px;
  }
  .mainPaddingDispatcher {
    padding: 20px 30px;
  }
  .mtcPadding {
    padding: 20px 22px;
  }
  .mtcPadding1 {
    padding: 20px 22px;
  }
  .mainPaddingDispatcher1 {
    padding: 5px 55px;
  }
  .mainCallPadding {
    margin: 20px 55px;
  }
}

.landingCont {
  margin-top: 16rem;
}

@media screen and (min-width: 1101px) and (max-width: 1600px) {
  .mainPadding {
    padding: 20px 60px;
  }
  .testGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    margin: 0 auto;
    align-items: center;
  }
  .testGridMtc {
    gap: 48px;
  }
  .testGridBenefits {
    gap: 48px;
  }
  .mtcPadding1 {
    padding: 20px 100px 20px 0px;
  }
  .mainPaddingDispatcher {
    padding: 52px 100px;
  }
  .mtcPadding {
    padding: 20px 0px 20px 100px;
  }
  .mainPaddingDispatcher1 {
    padding: 5px 224px;
  }
  .mainCallPadding {
    margin: 20px 300px;
  }
}

@media screen and (min-width: 792px) and (max-width: 1100px) {
  .mainPadding {
    padding: 20px 40px;
  }
  .testGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    margin: 0 auto;
    align-items: center;
  }
  .testGridMtc {
    gap: 48px;
  }
  .testGridBenefits {
    gap: 48px;
  }
  .mtcPadding {
    padding: 20px 0px 20px 70px;
  }
  .mtcPadding1 {
    padding: 20px 70px 20px 0px;
  }
  .mainPaddingDispatcher {
    padding: 52px 50px;
  }
  .mainPaddingDispatcher1 {
    padding: 5px 100px;
  }
  .mainCallPadding {
    margin: 20px 200px;
  }
}

.contentLanding {
  margin-top: 16%;
}

@media screen and (min-width: 600px) and (max-width: 791px) {
  .mainPadding {
    padding: 20px 30px;
  }
  .mainPaddingDispatcher {
    padding: 20px 30px;
  }
  .mtcPadding {
    padding: 20px 0px 20px 50px;
  }
  .mtcPadding1 {
    padding: 20px 50px 20px 0px;
  }
  .mainPaddingDispatcher1 {
    padding: 5px 30px;
  }

  .testGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin: 0 auto;
    align-items: center;
  }
  .testGridMtc {
    gap: 24px;
  }
  .testGridBenefits {
    gap: 24px;
  }
}

.navbar-top {
  top: 150px;
  position: fixed;
  z-index: 999999;
}

.cwLogo {
  width: 12vw;
}

.cwgaLogo {
  width: 30vw;
}

.nicoLogo {
  width: 5vw;
}
.progressiveLogo {
  width: 12vw;
}
.gaLogo,
.lancerLogo {
  width: 9vw;
}

@media screen and (max-width: 567px) {
  .cwLogo {
    width: 40vw;
  }

  .cwgaLogo {
    width: 80vw;
  }

  .nicoLogo {
    width: 40vw;
  }
  .progressiveLogo {
    width: 40vw;
  }
  .gaLogo,
  .lancerLogo {
    width: 40vw;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  border: none;
  padding: 8px;
}

.folderBody {
  display: block;
  max-height: 30vh;
  overflow: auto;
}
.folderHead,
.folderBody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
.folderHead {
  width: calc(
    100% - 1em
  ); /* scrollbar is average 1em/16px width, remove it from thead width */
}

.notesCard {
  right: 7.5rem;
  top: 40px;
  width: 45vw;
  border-radius: 5px;
  position: absolute;
  border: 1px solid #c4c4c4;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 35%) 0px 4px 14px 0px;
}
.notesCard .header {
  padding: 1rem 1.5rem;
  justify-content: space-between;
}

.notesCard .header .action p {
  opacity: 0.6;
  color: black;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.25rem;
}

.notesCard .header .heading {
  color: black;
  opacity: 0.6;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.2em;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #fff;
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}

@media screen and (max-width: 599px) {
  .chip {
    font-size: 12px;
  }
  .landingCont {
    margin-top: 17rem;
  }
  .notesCard {
    right: 0.5rem;
    width: 90vw;
  }
  .notesCard .header {
    white-space: nowrap;
  }
  .notesCard .header .heading {
    font-size: 14px;
    margin-right: 5px;
  }
  .notesCard .header .action {
    font-size: 14px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1820px) {
  .contentLanding {
    margin-top: 18%;
  }
}

@media screen and (min-width: 1900px) and (max-width: 2050px) {
  .landingCont {
    margin-top: 22rem;
  }
}

@media screen and (min-width: 2051px) and (max-width: 2200px) {
  .landingCont {
    margin-top: 23rem;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1899px) {
  .landingCont {
    margin-top: 20rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .landingCont {
    margin-top: 17.5rem;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2300px) {
  .landingCont {
    margin-top: 24rem;
  }
}

@media screen and (min-width: 2301px) and (max-width: 2800px) {
  .landingCont {
    margin-top: 26rem;
  }
}

@media screen and (min-width: 2801px) and (max-width: 2900px) {
  .landingCont {
    margin-top: 28rem;
  }
}

@media screen and (min-width: 2901px) {
  .landingCont {
    margin-top: 31rem;
  }
}

@media screen and (min-width: 828px) and (max-width: 1199px) {
  .contentLanding {
    margin-top: 23%;
  }
}

@media screen and (min-width: 698px) and (max-width: 827px) {
  .contentLanding {
    margin-top: 28%;
  }
  .landingCont {
    margin-top: 20rem;
  }
}

@media screen and (min-width: 530px) and (max-width: 697px) {
  .contentLanding {
    margin-top: 43%;
  }
}

@media screen and (min-width: 470px) and (max-width: 529px) {
  .contentLanding {
    margin-top: 53%;
  }
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.tag {
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #979797;
  opacity: 70%;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}

.tag-content {
  cursor: inherit;
  display: flex;
  color: #979797;
  font-family: "HKGrotesk-Regular";
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
.tag-active {
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #5468ff;
  background-color: #5468ff;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}

.tag-content-active {
  cursor: inherit;
  display: flex;
  font-family: "HKGrotesk-Regular";
  color: #fff;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.searchWrapper {
  background-color: white;
  --tw-border-opacity: 1;
  border: 1px solid rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.singleSelect__control {
  height: 3.5rem;
  margin-top: 4px;
  --tw-border-opacity: 1;
  background-color: white;
  border: 1px solid rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.rc-input-number-input {
  width: 100%;
  border: none;
  outline: none;
}

/* JoyRide Library custom styles starts */

.react-joyride__tooltip button {
  padding: 12px !important;
}
.react-joyride__tooltip div:nth-child(2) {
  justify-content: center !important;
}
.react-joyride__tooltip div:nth-child(2) div {
  flex: none !important;
}

.searchBtn {
  background-color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  transition: transform 0.3s ease;
}
.searchBtn:focus,
.inputCom:focus {
  outline: 0;
  outline: none !important;
}
.search.active .inputCom {
  width: 300px;
}
.search.active .searchBtn {
  transform: translateX(298px);
}

.cardman {
  height: calc(100vh - 25vh - 4rem);
}

.DocuMan {
  height: calc(100vh - 4rem);
}

.cardManImg {
  transform: translateX(-50%);
  width: 300px;
  z-index: 10;
}

.stepsCard {
  left: 50%;
  transform: translateX(-50%);
}

.landingMain {
  min-height: calc(100vh - 5rem);
}

.tester {
  clip-path: ellipse(100% 55% at 55% 55%);
}

.dispatchHeight {
  min-height: calc(100vh - 4rem);
}

.ntpBackground {
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("/Images/NTP_Truck.png");
  background-repeat: no-repeat;
  background-position: center;
  object-fit: "contain";
  background-size: cover;
}

#shadow-host-companion {
  display: none;
}

.test-grid {
  padding-top: 3rem;
  max-width: 900px;
  margin: 2rem auto;
  justify-content: center;
  align-content: center;
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 1200px) {
  .test-grid {
    grid-template-columns: 1fr;
  }
}

.ltLogin-grid {
  max-width: 1200px;
  margin: 0rem auto;
  justify-content: center;
  align-content: center;
  display: grid;
  gap: 8rem;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1200px) {
  .ltLogin-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1199px) {
  .ltLogin-grid {
    padding: 30px;
  }
}

.section-center {
  width: 90vw;
  padding-top: 3rem;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
  .video-center {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.subMenu {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.subMenu.showMenu {
  height: 7rem;
}

.subMenu.showMenuSmall {
  height: 4.5rem;
}

.title-sidebar-show {
  font-weight: bold;
}

.title-sidebar:hover {
  transition: color 0.2s linear;
  color: #ffd057;
}

.onhover {
  transition: all 0.2s linear;
  margin-left: 20px;
}

.subMenuActive {
  border-bottom: 2px solid #ffd057;
  font-weight: bold;
}

.subMenuhov:hover {
  transition: color 0.2s linear;
  color: #aeb8ff;
}

.myOptionClassName {
  font-weight: "bold";
  font-family: "HKGrotesk-Bold";
  /* font-size: larger; */
}

.drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px; /* Updated padding */
  border: 2px dashed #c4c4c4; /* Standard dashed border */
  border-radius: 10px; /* Added border-radius */
  transition: all 0.2s ease-in-out; /* Smooth transitions */
  gap: 10px; /* Gap for child elements */
  cursor: pointer;

  /* Adding a shadow to create the illusion of more space between dashes */
  /* box-shadow: 0 0 0 2px #fff, 0 0 0 4px #C4C4C4; */
}

.drop-area.dragover {
  border-color: #3b82f6; /* Tailwind blue-500 */
  background-color: #ebf8ff; /* Tailwind blue-100 */
}

.file-details {
  display: block;
  align-items: center;
}

.file-preview {
  max-height: 128px;
}

.login-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

@media (min-width: 1024px) {
  .login-main-container {
    flex-direction: row;
    padding: 10px;
  }
}

.login-container,
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.third-component {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.first-component {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-top: 50px;
  text-align: center;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
}

.main-image {
  border-radius: 15px;
  height: auto;
}

.number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.magnify {
  transition: transform 0.3s ease;
}

.magnify:hover {
  transform: scale(1.1);
}
